import { useCookieConfiguration } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'
import { ButtonTertiary } from '/features/buildingBlocks/Button'
import { Link } from '/features/buildingBlocks/Link'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { HeadingSm } from '/features/buildingBlocks/Heading'

import styles from './CookieBar.css'

export function CookieBar({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const language = useLanguage()
  const { __ } = useTranslate()
  const reportError = useReportError()

  const {
    configuration,
    acceptAll,
    rejectAll,
  } = useCookieConfiguration({
    availablePermissions: clientConfig.cookieConfiguration.availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const displayConsent = configuration?.show ?? false

  return displayConsent ? (
    <aside className={cx(styles.component, layoutClassName)} data-style-context='blue-darkest'>
      <ContainerMd>
        <div className={styles.layout}>
          <HeadingSm title={__`cookie-bar-title`} h={3} />
          <p>{__`cookie-bar-text`}</p>
          <Link href={routeMap.app.cookiePolicy({ language })} dataX='manage-cookies'>{__`cookie-bar-manage`}</Link>
          <div className={styles.actions}>
            <ButtonTertiary onClick={acceptAll} label={__`cookie-consent-accept`} dataX='accept-cookies' />
            <ButtonTertiary onClick={rejectAll} label={__`cookie-consent-reject`} dataX='reject-cookies' />
          </div>
        </div>
      </ContainerMd>
    </aside>
  ) : <aside style={{ display: 'none' }} />
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
